











import { DataTableHeader } from 'vuetify';
import { defineComponent } from '@vue/composition-api';
import MappingsEditComponent from '@/components/MappingsEditComponent.vue';
import router from '@/router';

export default defineComponent({
  components: {
    MappingsEditComponent,
  },
  setup() {
    const mappingStepTitle = 'Let\'s map the Work Order information that will be sent.';
    const isPublished = (router.app.$route.params.isPublished === 'true');
    const mappingStepHeaders: DataTableHeader[] = [
      {
        text: 'Work Order Template Field',
        align: 'start',
        value: 'fieldName',
      },
      { text: 'Label', value: 'label' },
      { text: 'As String', value: 'format' },
      { text: 'Empty Value', value: 'emptyValue' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const tabs = ['Mappings', 'Alerts', 'View Message', 'Endpoint Address'];

    const headers = [
      {
        text: 'Template Field',
        align: 'start',
        value: 'fieldName',
      },
      { text: 'Label', value: 'label' },
      { text: 'As String', value: 'format' },
      { text: 'Empty Value', value: 'emptyValue' },
    ];

    return {
      headers,
      mappingStepTitle,
      mappingStepHeaders,
      tabs,
      isPublished,
    };
  },
});
